<template>
  <div id="cancellation-and-refund-policy">
    <b-container class="base-container-x about-header-box" style="min-height:500px;background-color:#fff">
      <div v-if="loading == true" class="col-12">
        <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
      </div>
      <div v-if="loading == true" class="col-12 mt-4">
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
        <br/>
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
      </div>
      <div v-if="loading == false" class="col-12 mb-5">
        <div class="mt-5 mb-5 text-center">
          <b-img width="100px" :src="require('../../assets/images/logo/logo.png')"/>
          <div class="text-header mt-3" >
            <h2>{{ $t('World Yoga Alliance') }}</h2>
            <h2>{{ $t('Cancellation and Refund Policy') }}</h2>
          </div>
        </div>
        <p class="text-gray-light"><i>{{ $t('Last Updated: 29 April, 2021') }}</i></p>
        <p>{{ $t('At World Yoga Alliance (WYA COMMUNITY), we want to ensure that new members at') }} <a href="https://www.wyayoga.org/" target="_blank"><u>www.wyayoga.org</u></a> {{ $t('are happy with their benefits and that any changes to our policies are well-received by existing members. The following policy describes the process and eligibility for canceling or opting out of your WYA membership.') }}</p>
        <div class="mt-5">
          <h4>{{ $t('Eligibility') }}</h4>
          <p>{{ $t('There are two ways to be eligible to cancel your Membership:') }}</p>
          <ol>
            <li>{{ $t('New Members:') }}</li>
            <p>{{ $t('Within the past 30 days of becoming a first-time member with WYA Community and have subsequently decided that you no longer want to continue that membership.') }}</p>
            <li>{{ $t('Recent Changes to WYA Policy:') }}</li>
            <p>{{ $t(' Within 30 days of World Yoga Alliance providing notice about a new or updated policy and would like to opt out of your membership(s).') }}</p>
          </ol>
          <p>{{ $t('Although we are sorry to see you go, please find details below on how to request your Membership be canceled.') }}</p>
        </div>
        <div class="mt-5">
          <h4>{{ $t('New Member Cancellation Process') }}</h4>
          <ol>
            <li>{{ $t('To cancel, within 30 days after the start of your new, first-time Membership, please send an email to') }} <a href="mailto:contact@wyayoga.org" target="_blank" title="Email WYA Yoga Office"><u>contact@wyayoga.org</u></a> {{ $t('and provide the following information:') }}</li>
            <ul>
              <li>{{ $t('WYA ID Number;') }}</li>
              <li>{{ $t('Email address associated with the account;') }}</li>
              <li>{{ $t('Best phone number / WhatsApp Number to contact you;') }}</li>
              <li>{{ $t('Reason for cancellation (e.g., “I no longer desire to continue my membership.”)') }}</li>
            </ul>
            <li>{{ $t('If verified as eligible for cancellation, please be aware that we have no refund policy and we will not refund any amount in any condition that was previously payed for the application/membership fee but we will make donations your amount to any "Lord Shiva Temple or any similer organisation" in Thialnd or India during your application process within 30 days of the request.') }}</li>
            <li>{{ $t('Immediately upon sending you a donation receipt (as refund policy) via email, your membership will be terminated. This termination includes, but is not limited to:') }}</li>
            <ul>
              <li>{{ $t('Your rights to use the "World Yoga Alliance" name and trademarks, please see') }} <router-link class="text-primary-dark" :to="{ name: 'designationAndTrademark' }"><u>{{ $t('WYA Trademark Use Policy') }}</u></router-link>;</li>
              <li>{{ $t('All member benefits associated with your membership;') }}</li>
              <li>{{ $t('All access to your WYA account or any information in the account.') }}</li>
            </ul>
          </ol>
        </div>
        <div class="mt-5">
          <h4>{{ $t('New or Updated World Yoga Alliance Policy Opt Out Option') }}</h4>
          <ol>
            <li>{{ $t('To cancel, within 30 days after you receive notice from World Yoga Alliance (WYA Community) of a new or updated [membership] policy, please send an email to') }} <a href="mailto:contact@wyayoga.org" target="_blank" title="Email WYA Yoga Office"><u>contact@wyayoga.org</u></a> {{ $t('and provide the following information:') }}</li>
            <ul>
              <li>{{ $t('WYA ID Number;') }}</li>
              <li>{{ $t('Email address associated with the account;') }}</li>
              <li>{{ $t('Best phone number / WhatsApp Number to contact you;') }}</li>
              <li>{{ $t('Reason for cancellation (e.g., “I no longer desire to continue my membership.”)') }}</li>
            </ul>
            <li>{{ $t('World Yoga Alliance will review and process your request within 30 working days.') }}</li>
            <li>{{ $t('If verified as eligible for cancellation, please be aware that we have no refund policy and we will not refund any amount in any condition that payed for application/membership fee but we will make donations your amount to any "Lord Shiva Temple or any similer organisation" in Thialnd or India during your application process within 30 days of the request.') }}</li>
            <li>{{ $t('Immediately upon sending you a donation receipt (as refund policy) via email, your membership will be terminated. This termination includes, but is not limited to:') }}</li>
            <ul>
              <li>{{ $t('Your rights to use the "World Yoga Alliance" name and trademarks, please see') }} <router-link class="text-primary-dark" :to="{ name: 'designationAndTrademark' }"><u>{{ $t('WYA Trademark Use Policy') }}</u></router-link>;</li>
              <li>{{ $t('All member benefits associated with your membership;') }}</li>
              <li>{{ $t('All access to your WYA account or any information in the account.') }}</li>
            </ul>
          </ol>
        </div>
        <div class="mt-5">
          <h4>{{ $t('Expiration of Annual Membership') }}</h4>
          <p>{{ $t('You may also choose to let your annual membership expire by taking no action to renew at the end of your annual term. This will result in termination of your membership. Termination includes, but is not limited to:') }}</p>
          <ul>
            <li>{{ $t('Your rights to use the "World Yoga Alliance" name and trademarks, please see') }} <router-link class="text-primary-dark" :to="{ name: 'designationAndTrademark' }"><u>{{ $t('WYA Trademark Use Policy') }}</u></router-link>;</li>
            <li>{{ $t('All member benefits associated with your membership;') }}}</li>
            <li>{{ $t('All access to your WYA account or any information in the account.') }}}</li>
          </ul>
        </div>
        <div class="mt-5">
          <h4>{{ $t('Lifetime Member Cancellation Process and policy') }}</h4>
          <p>{{ $t('Upon your illegal activities misusing our name or trademark without any specific registration or license you may also get legal notice from World Yoga Alliance (WYA COMMUNITY) Please reply the WYA office as soon as possible within 7 working days. If there is no reply email to') }} <a href="mailto:contact@wyayoga.org" target="_blank" title="Email WYA Yoga Office"><u>contact@wyayoga.org</u></a> {{ ('this will result in termination of your membership. Termination includes, but is not limited to:') }}</p>
          <ul>
            <li>{{ $t('Your rights to use the "World Yoga Alliance" name and trademarks, please see') }} <router-link class="text-primary-dark" :to="{ name: 'designationAndTrademark' }"><u>{{ $t('WYA Trademark Use Policy') }}</u></router-link>;</li>
            <li>{{ $t('All member benefits associated with your membership;') }}}</li>
            <li>{{ $t('All access to your WYA account or any information in the account.') }}}</li>
          </ul>
        </div>
        <div class="mt-5">
          <h4>{{ $t('Certified Yoga School T&C Cancellation Policy:') }}</h4>
          <p>{{ $t('If they want to cancel their membership, they have to submit their training manual and standards of their previous training. The Manual is intellectual property of WYA and cannot be used once there is a cancelation or termination of you CYS membership.') }}</p>
          <p>{{ $t('Once you cancel the membership you are no longer a lifetime member of World Yoga Alliance and will be automatically recognized as RSY Registrar School of Yoga and continue to be an annual member.') }}</p>
          <ul>
            <li>{{ $t('Your rights to use the "World Yoga Alliance" name and trademarks, please see') }} <router-link class="text-primary-dark" :to="{ name: 'designationAndTrademark' }"><u>{{ $t('WYA Trademark Use Policy') }}</u></router-link>;</li>
            <li>{{ $t('All member benefits associated with your membership;') }}}</li>
            <li>{{ $t('All access to your WYA account or any information in the account.') }}}</li>
          </ul>
        </div>
        <div class="mt-5">
          <h4>{{ $t('Questions') }}</h4>
          <p>{{ $t('If you have any questions about cancellations and refunds, please email to') }} <a href="mailto:contact@wyayoga.org" target="_blank" title="Email WYA Yoga Office"><u>contact@wyayoga.org</u></a></p>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'CancellationAndRefundPolicy',
  title: ' | Cancellation and Refund Policy',
  data () {
    return {
      loading: true
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    await this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    this.loading = false
  },
  methods: {
    scrollToElement (id) {
      const element = document.getElementById(id)
      console.log('#id', id)
      const elementOffset = element.offsetTop - 80
      window.scroll({ top: elementOffset, behavior: 'smooth' })
    },
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>

<style scoped>
  #cancellation-and-refund-policy {
  /* Lock Text */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  .table-of-contents li:hover {
    cursor:pointer;
  }
  .text-header {
    color: #B4341D
  }
</style>
